html {
    font-size: 14px;
}
body {
    background-color: #f4f6f8;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    margin: 0;
}

img {
    max-width: 100%;
    display: inline-block;
    vertical-align: middle;
}

.MuiModal-root .location-sticky-header {
    top: 0;
}

.SnackbarItem-message {
    font-size: 14px !important;
}

.SnackbarItem-message .MuiSvgIcon-root {
    font-size: 30px !important;
}

.ReactModal__Overlay {
    z-index: 1500 !important;
}

.MuiInputBase-input:disabled,
.MuiInputBase-input.Mui-disabled {
    background-color: #ddd !important;
    opacity: 0.5 !important;
}
.fontSizeIncrease .MuiFormHelperText-contained {
    font-size: 0.9rem;
}

/* Date Range Picker */

.flatpickr-months .flatpickr-month,
.flatpickr-weekdays,
span.flatpickr-weekday,
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month
    .flatpickr-monthDropdown-months
    .flatpickr-monthDropdown-month {
    background: #bd2531;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.flatpickr-calendar.arrowTop:after {
    border-bottom-color: #bd2531;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    background: #bd2531;
    border-color: #bd2531;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
    -webkit-box-shadow: -10px 0 0 #bd2531;
    box-shadow: -10px 0 0 #bd2531;
}

span.flatpickr-weekday {
    color: #ffffff;
    font-size: 14px;
}

.rangeMode .flatpickr-day {
    font-size: 13px;
}

@media (min-width: 450px) {
    .mobile-view-mt {
        margin-top: 8px;
    }
}
@media (max-width: 450px) {
    .mobile-view-mt {
        margin-top: 22px;
    }
    .sprague-logo {
        display: none;
    }
}
@media (max-width: 330px) {
    .mobile-view-mt {
        margin-top: 72px !important;
    }
}

.column-Sticky {
    position: sticky;
    left: 0;
    background: white;
}
@media (max-width: 600px) {
    .mobile-w100 {
        width: 100%;
    }
    .mt-8 {
        margin-top: 8px;
    }
    .css-8vvsi0 > .MuiButton-root:first-child {
        margin-right: 0 !important;
    }
}
.mobile__pdf__container #viewerContainer .pdfViewer {
    overflow: hidden;
    overflow: -moz-hidden-unscrollable;
}
.mobile__pdf__container .hidden {
    display: none;
}
#pdfDocument:has(.react-pdf__Document) {
    overflow: scroll;
}
.react-pdf__Page__canvas {
    margin: 0 auto;
    /* width: 90% !important; */
    height: 100% !important;
}
.webkit-over-ios {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}
@supports (-webkit-overflow-scrolling: touch) {
    .webkit-over-ios {
        /* height:calc(100vh+300px); */
        max-height: calc(100vh - 200px);
        max-width: 100%;
        /* width:100%; */
    }
}
/* .MuiPaper-root.MuiPaper-outlined.MuiPaper-rounded.MuiTableContainer-root{
overflow-x:scroll;
-webkit-overflow-scrolling: touch;
} */
.pdf-box:last-of-type {
    margin-bottom: 40vh;
}
#doc-container {
    margin-bottom: 40vh;
}
.react-transform-wrapper.transform-component-module_wrapper__SPB86 {
    max-width: 100%;
    max-height: calc(100vh - 50px);
}

.transform-component-module_wrapper__SPB86 {
    width: 100% !important;
}

#back-icon {
    background-color: #bd2531;
}
.suggestion {
    height: 34px;
    cursor: pointer;
    align-items: center;
    border: 1px solid grey;
    z-index: 999;
}
.suggestion:hover {
    background-color: rgba(63, 106, 224, 0.473);
}
td.captured-pest-table {
    border: 1px solid #dddddd;
    /* text-align: left; */
    padding: 8px;
}

th.captured-pest-table {
    border: 1px solid #dddddd;
    /* text-align: left; */
    padding: 8px;
}

/* input.flatpickr-input.flatpickr-mobile {
        display:none
    } */

    div.filtertimerange .MuiPaper-root{
        overflow: visible;
    }
    
    div.react-datepicker-popper{
        z-index:9999999999;
    }
    
    .MuiDialog-container .MuiPaper-root {
        overflow:visible;
    }